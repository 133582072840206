import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import Fontawesom from "lit-fontawesome";

@customElement("to-top-button")
export class BackToTop extends LitElement{
    @property({type:Boolean, state:true})
    showButton = false;

    protected firstUpdated(): void {
        window.addEventListener("scroll", ()=>{
            this.showButton = window.scrollY > 50;
        })
    }
    scrollToTop(){
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',   
        });
    }

    render(){
       return this.showButton ? this.renderButton() : "";
    };
    renderButton(){
        return html `
            <div class="backtotop " @click="${this.scrollToTop}">
                <div class="icon" >            
                    <i class="fas fa-arrow-up"></i>
                </div>
            </div>`
    }

    static styles = [
        Fontawesom,
        css`
        .backtotop{
            position: fixed;
            bottom: 20px;
            right: 20px;
            z-index: 999;
            transition: 0.5s;

            width: 50px;
            height: 50px;
            background: rgba(0, 0, 0, 0.5);
            color: white;
            text-align: center;
            border-radius: 5px;
            
            .icon{
                height: 30%;
                width: 30%;
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-right: -50%;
                transform: translate(-50%, -50%);
                top:44%;
            }} 
        `
    ]
}