import "./style/main.scss";
import ApiService from "./services/apiService";
import MiscService from "./services/miscService";
import NavigationService from "./services/navigationService";

// Services erstellen
ApiService.create();
NavigationService.create();
MiscService.create();

// Componenten registrieren
import "./components/utilities/logo";
import "./components/utilities/backToTop";
import "./components/utilities/iconLink";
import "./components/utilities/socialLinks";
import "./components/navigation/mainNavigation";
import "./components/navigation/footerNavigation";
