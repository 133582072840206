import { NavigationItem } from "../classes/navigationItem";
import ApiService from "./apiService";

export default class NavigationService{    
	public static instance : NavigationService;
    constructor (){ }

    public static create(){
		NavigationService.instance = new NavigationService();
	}

    public async GetMainNavigation(): Promise<NavigationItem[]>{
        let items = [] as NavigationItem[];
        const url = "Navigation/GetMainNavigation";
        items = await ApiService.Instance.GetData(url) as NavigationItem[];
        return items;
    }    
    public async GetFooterNavigation(): Promise<NavigationItem[]>{
        let items = [] as NavigationItem[];
        const url = "Navigation/getfooternavigation";
        items = await ApiService.Instance.GetData(url) as NavigationItem[];
        return items;
    }
    public async GetLogo():Promise<string>{
        let logoUrl = "";
        const url = "Navigation/getLogo";        
        var apiLogo= await ApiService.Instance.GetData(url);
        logoUrl = apiLogo? apiLogo.toString(): logoUrl;
        return logoUrl;
    }
}