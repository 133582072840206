export class NavigationItem{
    public key: string;
    public name: string;
    public url: string;

    public constructor(item:any){
        this.key = item.key ?? "00000000-0000-0000-0000-000000000000";
        this.name = item.name ?? "";
        this.url = item.url ?? "#";
    }
}