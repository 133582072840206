import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { IconLink } from "../../classes/iconLink";
import MiscService from "../../services/miscService";
import "./iconLink";

@customElement("social-links")
export default class SocialLinks extends LitElement{
    @property({type:String})
    node = "";
    @property({type:String})
    alias= ""
    @property({type:Array, state:true})
    links = [] as IconLink[]

    async GetLinks(){
        this.links = await MiscService.instance.GetIconLinks(this.node, this.alias)
    }

    async firstUpdated() {
        await this.GetLinks();
    }

    render(){
        return  this.links && this.links.length>0 ? 
                html`
                    <div class="socialLinks">
                        ${this.links.map((link:IconLink) => {
                            html`
                                <icon-link .link="${link}"></icon-link>
                            `
                        })}                        
                    </div>
                `: "";
    };

    static styles = css`
        .socialLinks{ 
        .iconLink{
            font-size: var(--fontsize-small, 0.75rem);
            padding: var(--space-xxs, 0.2rem);
            color:var(--fontcolor-links, #dbdbdb);
            text-decoration: none;
        }}
    `;
}