import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { NavigationItem } from "../../classes/navigationItem";
import NavigationService from "../../services/navigationService";
import "./navigation";

@customElement("footer-navigation")
export default class FooterNavigation extends LitElement{
    @property({type:Array, state:true})
    items = [] as NavigationItem[];

    protected async firstUpdated(){
        this.items = await NavigationService.instance.GetFooterNavigation();
    }
    render(){
        return html`
            <navigation-list .items="${this.items}" .navType="${"footernavigation"}"></navigation-list>          
        `;
    }
}