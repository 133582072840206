import axios, { AxiosRequestConfig } from "axios";
export default class ApiService{
    static instance: ApiService;
    private baseUrl: string = "/umbraco/api/";
    private baseUrlSurface: string = "/umbraco/surface/"

    public static get Instance(){
        return ApiService.instance;
    }

    public static async create(){
		ApiService.instance = new ApiService();
	}

    async GetData(relativeUrl:string, params = {}, isSurface=false):Promise<object>{
        return await this.CallApi(relativeUrl, true, params, isSurface);       
    }
    async PostData(relativeUrl:string, params = {}, isSurface=false):Promise<object>{        
        return await this.CallApi(relativeUrl, false, params, isSurface);         
    }

    private async CallApi(relativeUrl:string, isGet:boolean = true, params = {}, isSurface=false):Promise<object>{
        var data = undefined; 
        const baseUrl = isSurface? this.baseUrlSurface : this.baseUrl;
        const url = baseUrl+relativeUrl;
        const config: AxiosRequestConfig = {method: isGet ? "get" :"post"};
        if(params){
            config["params"]= params;
        }
        let response;
        if(isGet){
            response = await axios.get(url, config)
            if(response.status == 200){
                data = response.data;
            }
        }else{
            response = await axios.post(url, config)
            if(response.status == 200){
                data = response.data;
            }
        }
        return data;
  
    }
}