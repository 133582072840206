import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { NavigationItem } from "../../classes/navigationItem";
import NavigationService from "../../services/navigationService";
import "./navigation";

@customElement("main-navigation")
export default class MainNavigation extends LitElement{
    @property({type:Array, state:true})
    items = [] as NavigationItem[];

    protected async firstUpdated(){
        this.items = await NavigationService.instance.GetMainNavigation();
        this.items.unshift(new NavigationItem({name: "Home", url:"/"}));
    }
    render(){
        return html`
            <navigation-list .items="${this.items}" .navType="${"mainnavigation"}"></navigation-list>
                       
        `;
    }
}