import { IconLink } from "../classes/iconLink";
import ApiService from "./apiService";

export default class MiscService{    
	public static instance : MiscService;
    constructor (){ }

    public static create(){
		MiscService.instance = new MiscService();
	}

    public async GetIconLinks(nodeKey:string, alias:string): Promise<IconLink[]>{
        let items = [] as IconLink[];
        const url = "Misc/GetIconLinks";
        const params = {
            nodeKey, 
            alias
        }
        items = await ApiService.Instance.GetData(url, params) as IconLink[];
        return items;
    }    

    // public async RenderBlockGrid(modelKey:string){
    //     const url = "Render/RenderBlogGrid";
    //     const params = {
    //         modelKey
    //     };
    //     await ApiService.Instance.GetData(url, params, true);
    // }
    
}