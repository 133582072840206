import { css, html, LitElement} from "lit";
import { customElement, property } from "lit/decorators.js";
import NavigationService from "../../services/navigationService";

@customElement("icedgarden-logo")
export default class Logo extends LitElement{
    @property({type:String, state:true})
    logoUrl = ""; 
       
    GetLogo = async() => {
        this.logoUrl = await NavigationService.instance.GetLogo();
        return true;
    }

    async firstUpdated() {
        await this.GetLogo();
    }

    render(){
        return html`
        <div class="logo" >
            <a href="/">
                <img src="${this.logoUrl}"></img>
            </a>
        </div>
        `;
    }
    static styles = css`
        .logo{    
            margin-right: var(--space-default, 1rem) ;
            img{        
                border-radius: 50%;
                height: 100px;
            }
        }
    `;
    
}