import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { NavigationItem } from "../../classes/navigationItem";

@customElement("navigation-list")
export default class Navigation extends LitElement{
    @property({type:Array})
    items = [] as NavigationItem[]; 
    @property({type:String})
    navType = ""; 

    GetActiveClass(item:NavigationItem){
        const currentPath = window.location.pathname;
        return item.url == currentPath ? "active": "";
    }
    static styles= css`
        .navigationItem{
            padding: var(--space-xs, 0.5rem);
            text-decoration: none;
            color: var(--fontcolor-default, black);
            font-size: var(--fontsize-default, 1rem);
        }
        .mainnavigation .navigationItem.active{
            color: var(--color-ig-blue, #6393a3);
            text-decoration: underline
        }
        .footernavigation .navigationItem{
            font-size: var(--fontsize-small, 0.75rem);
            padding: var(--space-xxs, 0.3rem);
            color: var(--fontcolor-links,#6393a3);
        }
    `

    render(){
        return  html`
                <div class="navigation ${this.navType}">
                     ${this.items.map((item:NavigationItem) => {
                        return html`
                            <a href="${item.url}" alt="${item.name}" class="navigationItem ${this.GetActiveClass(item)}">${item.name}</a>
                        `
                    })}  
                </div>                           
                `
    }
} 