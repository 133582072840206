import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { IconLink as IconLinkClass } from "../../classes/iconLink";

@customElement("icon-link")
export default class IconLink extends LitElement{
    @property({attribute: false})
    link = {} as IconLinkClass;

    render(){
        return html`
            <a class="iconLink" href="${this.link.url}" target="${this.link.target}">
            <!-- <font-awesome-icon :icon="link.icon" v-if="link.icon" /> -->
                ${this.link.title}
            </a>
        `
    }
}